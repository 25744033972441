import { getTabURL } from '@/components/platform/GroupDashboard/layers/getTabURL';
import { GroupDashboardTab, GroupDashboardTabUrl } from '@/components/platform/GroupDashboard/typedefs';
import { ChatInfoTab, ChatRouteQueryParams } from '@/components/platform/Chat/chat.typedefs';
import { AnyObject } from '@/typedefs/common';
import { HistoryTab, PaymentTab } from '@/components/platform/Payment/PaymentsPage/constants';

export const ROUTES = {
  home: '/',
  hire: '/hire',
  type: '/type',
  hireSignUp: '/hire#sign-up',
  courses: (courseSlug: string, params: AnyObject = {}): string => {
    const queryParams = new URLSearchParams(params);

    return `/courses/${courseSlug}?${queryParams.toString()}`;
  },
  coursesParttime: '/courses/parttime',
  blog: '/blog',
  softDeadline: '/soft-deadline',
  softDeadlineAdjust: '/soft-deadline/adjust',
  landings: {
    quizResult: '/quiz-result',
    contentFocused: '/content-focused',
    skillBased: '/quiz-result/skills',
  },
  team: '/team',
  price: '/prices',
  englishManagement: '/english-management',
  techCheckManagement: {
    questions: {
      index: '/tech-check-management/questions-editor',
      notFound: '/tech-check-management/questions-editor/404',
      topic: '/tech-check-management/questions-editor/topic',
    },
    sessions: {
      index: '/tech-check-management/sessions',
      participants: '/tech-check-management/sessions/participants',
      participantsBySessionId: (sessionId: number): string => (
        `/tech-check-management/sessions/participants/${sessionId}`
      ),
      questions: '/tech-check-management/sessions/questions',
    },
    statistics: '/tech-check-management/statistics',
    templates: {
      index: '/tech-check-management/templates',
      create: '/tech-check-management/templates/create',
      edit: '/tech-check-management/templates/edit',
    },
  },
  subscriptionsManagement: {
    index: '/subscriptions-management',
    deactivate: '/subscriptions-management/deactivate-subscription',
    transfer: '/subscriptions-management/transfer-subscription',
    changePlan: '/subscriptions-management/change-plan',
  },
  examsManagement: '/exams-management',
  notFound: '/404',
  forbidden: '/403',
  newFaq: '/faq',
  supportViber: '/support/viber',
  reviews: '/reviews',
  permissions: '/permissions',
  auth: {
    hireSignUp: '/hire/sign-up',
    signIn: '/sign-in',
    signUp: '/sign-up',
    forgotPassword: '/forgot-password',
    forgotPasswordMessage: '/forgot-password-message',
    resetPassword: '/reset-password',
    signInAsUser: '/sign-in-as-user',
    signInWithCode: '/sign-in-with-code',
  },
  payments: {
    index: '/payments',
    newPayment: '/payments/new',
    tab: (tab: PaymentTab) => `/payments/${tab}`,
    history: (tab: HistoryTab) => `/payments/history/${tab}`,
  },
  user: {
    application: '/application',
    applications: '/applications',
    assignment: '/assignment',
    agreement: {
      index: (courseSlug: string) => `/agreement/${courseSlug}`,
      fulltime: '/agreement/fulltime',
      flex: '/agreement/flex',
    },
    profile: '/profile',
    career: '/profile/career',
    settings: '/settings',
    payments: '/payments',
    paymentsStatistic: '/payments-statistic',
    paymentsIncome: '/payments-income',
    payment: '/payment',
    paymentRate: '/payment-rate',
    paymentError: '/payment/error',
    paymentRateSuccess: '/payment/rate/success',
    paymentRateSupport: '/payment/rate/support',
    paymentSkip: '/payment/new-skip',
    paymentAdmin: '/payment/admin/purchase-link',
    paymentAdminSubscriptions: '/payment/admin/subscriptions',
    paymentAdminLoanSubscriptions: '/payment/admin/loan-subscriptions',
    paymentAdminProcessExternalPayment: '/payment/admin/process-external-payment',
    paymentAdminProcessInstalments: '/payment/admin/process-instalments',
    paymentAdminRefund: '/payment/admin/refund',
    paymentAdminInternalRefund: '/payment/admin/internal-refund',
    paymentStripeChangeMethod: '/payment/stripe/change-method',
    uploadProofOfRevenue: '/payment/upload-proof-of-revenue',
    course: '/course',
    vacancies: '/vacancies',
    wallet: '/wallet',
    learn: '/learn',
    learnCourse: (courseSlug: string): string => (
      `/learn?course=${courseSlug.replace('-parttime', '')}`
    ),
    learnTopic: ({
      moduleSlug,
      topicSlug,
    }: {
      moduleSlug: string;
      topicSlug: string;
    }): string => (
      `/learn/${moduleSlug}/${topicSlug}`
    ),
    checkout: '/checkout',
    subscriptions: '/subscriptions',
    referral: '/invite',
    withdrawMoney: '/invite/withdraw',
    event: '/event',
    eventNoAccess: '/event/no-access',
    events: '/events',
    eventsEnglishLesson: '/events/english-lesson',
    eventsWebinar: '/events/webinar',
    eventsMarathons: '/events/marathon',
    eventsOffersGenerate: '/events/offers/generate-link',
    eventsOffers: '/events/offers',
    webinar: (webinarSlug: string): string => (
      `/webinar/${webinarSlug}`
    ),
    webinarLP: (webinarSlug: string): string => (
      `/lp/webinar/${webinarSlug}`
    ),
    webinarConsultation: (webinarSlug: string): string => (
      `/webinar-consultation/${webinarSlug}`
    ),
    join: '/join',
    installments: '/installments',
    schedule: '/schedule',
    scheduleGroup: '/schedule/group',
    scheduleEventByGroupAndId: (groupName: string, eventId: number): string => (
      `/schedule/group/${groupName}/${eventId}`
    ),
    scheduleTemplate: '/schedule/template',
    scheduleEvent: '/schedule-event/',
    english: '/english',
    learnEdit: '/learn/edit',
    adminTools: '/management-admin-tools',
    homeworksReviews: {
      flow: '/homework-reviews/flow',
      list: '/homework-reviews/list',
      history: '/homework-reviews/history',
      review: (attemptId: number): string => (
        `/homework-reviews/review/${attemptId}`
      ),
    },
    homeworkReview: '/homework-review',
    studySchedule: '/study-schedule',
    notifications: '/notifications',
    streaks: '/streaks',
  },
  guidelinesEditor: {
    guidelines: '/guidelines',
    new: '/guidelines/new',
    edit: '/guidelines/edit',
  },
  lmsEditor: {
    learnEdit: '/learn/edit',
    attachmentTask: '/task-attachment',
    quiz: '/quiz',
    githubTask: '/task-github',
    codeEditorTask: '/task-code-editor',
    markupTask: '/task-markup',
    webflowTask: '/task-webflow',
    videos: '/videos',
    versions: '/versions',
    theory: '/theory',
    tasks: '/tasks',
    add: '/add',
    edit: {
      index: '/learn/edit',
      videos(params: {
        professionSlug?: string;
        moduleSlug: string;
        topicSlug: string;
        learnVideoId?: number;
      }): string {
        const {
          professionSlug,
          moduleSlug,
          topicSlug,
          learnVideoId,
        } = params;

        const videoSpecific = learnVideoId
          ? `/${learnVideoId}`
          : '';

        return `/learn/edit/${professionSlug}/${moduleSlug}/${topicSlug}/videos${videoSpecific}`;
      },
    },
    courseTopic(
      params: {
        professionSlug: string;
        moduleSlug: string;
        topicSlug: string;
      },
    ): string {
      const {
        professionSlug,
        moduleSlug,
        topicSlug,
      } = params;

      return `/learn/edit/${professionSlug}/${moduleSlug}/${topicSlug}`;
    },
    course: '/course',
    module: '/module',
    topic: '/topic',
    editTask: '/edit-task',
    editCourse: '/edit-course',
    editModule: '/edit-module',
    addExisting: '/add-existing',
    createFromExisting: '/create-from-existing',
    courseTasks: '/course-tasks',
    versionsHistory: '/version-history',
    dailyTasks: '/daily-tasks',
    courseTasksType: {
      morningTasks: '/course-tasks/morning-tasks',
      assignmentTasks: '/course-tasks/assignment-tasks',
      liveCodingTasks: '/course-tasks/live-coding-tasks',
      testTaskPreviews: '/course-tasks/task-previews',
    },
  },
  contractors: {
    baseRoute: 'contractors',
    englishTeachers: {
      management: '/contractors/english-teacher/management',
      statistics: '/contractors/english-teacher/statistics',
    },
    externalMentors: {
      management: '/contractors/external-mentor/management',
      statistics: '/contractors/external-mentor/statistics',
    },
    others: {
      management: '/contractors/others/management',
      statistics: '/contractors/others/statistics',
    },
    timeline: '/contractors/timeline',
  },
  subscriptions: {
    billing: '/subscriptions/billing',
    manage: '/subscriptions/manage',
    active: '/subscriptions/active',
    archive: '/subscriptions/archive',
  },
  subscription: {
    index: '/subscription',
    subscriptionPay: '/subscription/pay',
    subscriptionPurchaseLink: '/subscription/purchase-link',
    billingAddress: '/subscription/billing-address',
  },
  subscriptionPlan: (planId: string) => ({
    billingAddress: `/subscription/${planId}/billing-address`,
  }),
  vacancies: {
    all: '/vacancies',
    applying: '/applying',
    interviewFeedback: '/interview-feedback',
  },
  checkout: '/checkout',
  applicationSurvey: {
    new: {
      query: 'new',
      step: -1,
    },
  },
  assignment: {
    'test-editor': {
      query: 'test-editor',
      step: 2,
      pathname: '/assignment/[courseUserId]/test-editor',
    },
  },
  achievements: '/achievements',
  liveCoding: 'live-coding',
  dailyTasks: 'daily-tasks',
  learn: {
    applicationsHistory: '/applications-history',
    application: '/application',
    newFtApplication: '/learn/application/new',
    practice: 'practice',
    theory: 'theory',
    video: 'video',
    selectCourse: '/select-course',
    course: (courseSlug: string) => ({
      topic: (topicSlug: string) => ({
        index: `/learn/${courseSlug}/${topicSlug}`,
        practice: {
          index: `/learn/${courseSlug}/${topicSlug}?section=practice`,
          testTask: (testTaskSlug: string) => (
            `/learn/${courseSlug}/${topicSlug}?section=practice&testTaskSlug=${testTaskSlug}`
          ),
        },
        video: {
          index: `/learn/${courseSlug}/${topicSlug}?section=video`,
          videoId: (videoId: number) => (
            `/learn/${courseSlug}/${topicSlug}?section=video&videoId=${videoId}`
          ),
        },
        theory: {
          index: `/learn/${courseSlug}/${topicSlug}?section=theory`,
        },
      }),
    }),
  },
  mobilePractice: {
    taskDescription: 'task-description',
    editor: 'editor',
    tests: 'tests',
    browser: 'browser',
  },
  signInWithCode: {
    code: 'code',
  },
  settings: {
    changePassword: `/settings/change-password`,
    setYourPassword: {
      index: `/settings/set-your-password`,
      welcome: `/settings/set-your-password?welcome=true`,
    },
    countryAndLanguage: `/settings/country-and-language`,
    changeEmail: `/settings/change-email`,
    deleteAccount: `/settings/delete-account`,
    personalData: `/settings/personal-data`,
    notificationPreferences: `/settings/notification-preferences`,
    editStudySchedule: `/settings/notification-preferences/edit-schedule`,
    chatsNotificationPreferences: `/settings/notification-preferences/chats`,
  },
  admissionProgress: '/admission-progress',
  admissionProgressFaq: '/admission-progress/faq',
  autoTechChecks: {
    index: '/auto-tech-check',
    error: '/auto-tech-check/error',
    template: (templateId: number) => `/auto-tech-check/template/${templateId}`,
  },
  techCheck: {
    getConnectRoute: (uuid: string) => `/tech-check/${uuid}`,
  },
  groupDashboard: {
    group: {
      index: `/group/progress`,
      tab: (tab: GroupDashboardTab) => `/group/${getTabURL(tab)}`,
    },
    groupManage: {
      index: '/group-manage',
      groupName: (groupName: string) => ({
        index: `/group-manage/${groupName}`,
        noAccess: `/group-manage/${groupName}/no-access`,
        tab: (tab: GroupDashboardTabUrl) => `/group-manage/${groupName}/${tab}`,
      }),
    },
  },
  registration: {
    index: '/registration',
  },
  consultation: {
    index: '/consultation',
    signUpSurvey: (params: AnyObject = {}) => {
      const queryParams = new URLSearchParams(params);

      return `/consultation/sign-up-survey?${queryParams.toString()}`;
    },
  },
  application: {
    signUpSurvey: (params: AnyObject = {}) => {
      const queryParams = new URLSearchParams(params);

      return `/application/sign-up-survey?${queryParams.toString()}`;
    },
  },
  codeReview: '/code-review',
  lessonMaterials: {
    id: (lessonId: number) => ({
      index: `/lesson-materials/${lessonId}`,
    }),
  },
  chats: {
    index: '/chats',
    info: {
      index: '/info',
      tab: (id: string, targetTab: ChatInfoTab | null) => (
        targetTab
          ? `/chats/${id}/info/${targetTab}`
          : `/chats/${id}`
      ),
    },
    threads: '/chats/threads',
    automatedMessages: {
      new: '/chats/automated-messages/new',
      index: '/chats/automated-messages',
    },
    search: '/chats/search',
    savedMessages: '/chats/saved',
  },
  chat: (chatId: number) => ({
    index: `/chats/${chatId}`,
    withParams: (params: ChatRouteQueryParams) => {
      const queryParams = new URLSearchParams(params as Record<string, any>);

      return `/chats/${chatId}?${queryParams.toString()}`;
    },
    pinnedMessages: `/chats/${chatId}/pinned`,
    permissions: `/chats/${chatId}/permissions`,
  }),
  interception: '/interception',
  featuresPricing: '/features-pricing',
  webflowQuizControl: '/en-us/l/quiz-v2/get-started',
  webflowQuizVariation: 'en-us/l/quiz-v3/get-started',
  freeTrial: '/plans/free-trial',
  jsActivationQuiz: '/learn/test-drive-en/test-drive-activation-intro-en',
  pythonActivationQuiz: '/learn/py-test-drive-en/py-td-intro-en',
};
