export enum Features {
  SidebarNavCourses = 'sidebar_nav_courses',
  SidebarNavDashboard = 'sidebar_nav_dashboard',
  SidebarNavCalendar = 'sidebar_nav_calendar',
  SidebarNavChats = 'sidebar_nav_chats',
  SidebarNavEvents = 'sidebar_nav_events',
  SidebarNavEnglish = 'sidebar_nav_english',
  SidebarNavVacancies = 'sidebar_nav_vacancies',
  SidebarNavPayments = 'sidebar_nav_payments',

  LessonGuidelines = 'lesson_guidelines',
  VacanciesMobileView = 'vacancies_mobile_view',
  PaymentsMethods = 'payments_methods',
  CodeEditorLanguageServer = 'code_editor_language_server',
  GoogleCitiesSelector = 'google_cities_selector',
  LmsGlobalSearch = 'lms_global_search',
  ChatEntitiesInGlobalSearch = 'chat_entities_in_global_search',
  AttachmentTaskHistoryRedesign = 'attachment_history_redesign',
  LmsMobileEditor = 'lms-mobile-editor',
  SubscriptionLoans = 'subscription_loans',
  FinesCalculation = 'fines_calculation',
  SolidPaymentsConversion = 'solid_payments_conversion',
  WebinarsSection = 'webinars_section',
  UpcomingLinearFlowBanner = 'upcoming_linear_flow_banner',
  ReviewsLanding = 'reviews_landing',
  ShowPricesOnPtPlLandings = 'show_prices_on_pt_pl_landings',
  CourseBenefitsWithPricesSectionRefactoring = 'course_benefits_with_prices_section_refactoring',
  ShowTeamMembersBasedOnDomain = 'show_team_members_based_on_domain',
  RecruitersLanding = 'recruiters_landing',
  RecruitmentVacancy = 'vacancies_recruitment_course',
  EmployersReviewsSection = 'employers_reviews_section',
  VideoReviewsSection = 'video_reviews_section',
  UpcomingCourseMonthPayments = 'upcoming_course_month_payments',
  SalaryInIt = 'salary_in_it_chart',
  StudentsMapSection = 'students_map_section',
  PlatformPeerReview = 'platform_peer_review',
  ProofOfRevenueUploading = 'proof_of_revenue_uploading',
  PreparationCourseWithModuleProgress = 'preparation_course_with_module_progress',
  DeleteAccountFlow = 'delete_account_flow',
  SubscriptionGrantsTab = 'subscription_grants_tab',
  AdminToolsPage = 'admin_tools_page',
  SkillFilterForCandidatesPage = 'skill_filter',
  UnlimSubscriptionsCourseSelection = 'unlim_subscriptions_course_selection',
  UnlimSubscriptionsChangeTrackModal = 'unlim_subscription_change_track_modal',
  EnglishCourseRedirectButton = 'english_course_redirect_button',
  RedirectAnonymousFromHiddenDomains = 'redirect_anonymous_from_hidden_domains',
  MobileAppIOSPrompt = 'mobile_app_ios_prompt',
  MobileAppAndroidPrompt = 'mobile_app_android_prompt',
  MobileAppMiniBanner = 'mobile_app_mini_banner',
  /** @deprecated */
  NRCInterceptionPopup = 'non_relevant_countries_interception_popup',
  WebFlowReactSupport = 'webflow-react-support',
  ShowEnlgishSubscriptionTooltip = 'show_english_subscription_if_student_has_group',
  ButtonsForAnswersOnRecruiterOffer = 'buttons_for_answers_on_recruiter_offer',
  MarketingConsentFeature = 'marketing_consent_feature',
  CookieYesBanner = 'cookie_yes_banner',
  LinkedinExperienceImport = 'linkedin_experience_import',
  CalendarMobileView = 'calendar_mobile_view',
  NPSSurvey = 'nps_survey',
  LMSSurvey = 'lms_survey',
  CareerTestSurvey = 'career_test_survey',
  MonthPaymentsAmountCurrency = 'month_payments_amount_currency',
  ChatsPagination = 'chats_pagination',
  ChatsListRefactoring = 'chats_list_refactoring',
  ChatThreadSubscription = 'chat_thread_subscription',
  CreateModuleFromExisting = 'create_module_from_existing',
  DarkMode = 'dark_mode',
  ChatMobileView = 'chat_mobile_view',
  ChatMessageTestTaskLinking = 'chat_message_test_task_linking',
  ChatSlackToPlatformMigrationBanner = 'migrating_chats_from_slack_to_platform_banner',
  ThreadOpeningOnTheSameScreen = 'chat_thread_separate_view',
  MateInstalmentSubscriptionClosedAccessBanner = 'mate_instalment_subscription_closed_access_banner',
  PermissionsManagementPage = 'permissions_management_page',
  Forum = 'forum',
  EditorTaskFormsFilesModule = 'code_editor_form_files_module',
  MobileTemplateView = 'mobile_calendar_template_view',
  AutoTechChecks = 'auto_tech_checks',
  CurrentThingPlatformLogo = 'support_current_thing_platform_logo',
  SandboxChatWidget = 'sandbox_chat_widget',
  SpecialOfferBanner = 'special_offer_banner',
  DefaultNoindex = 'default_noindex',
  ProofOfRevenueFeedbackForm = 'proof_of_revenue_feedback_form',
  BlogLanding = 'blog_landing',
  GroupDeadlines = 'group_deadlines',
  LearnVideoVersioning = 'learn_video_versioning',
  DeleteAccountFlowRefactoring = 'delete_account_flow_refactoring',
  ReviewWithAI = 'review_with_ai',
  ReviewCodeEditorAttemptWithAI = 'review_code_editor_attempt_with_ai',
  InvidInTheLmsEditor = 'invid_in_lms_editor',
  StudentsHomeworkDashboard = 'group_dashboard.homework_tab',
  StudentsMorningTasksDashboard = 'group_dashboard.morning_tasks_tab',
  StudentsAttendanceDashboard = 'group_dashboard.attendance_tab',
  StudentsEnglishDashboard = 'group_dashboard.english_tab',
  StudentsTypingDashboard = 'group_dashboard.typing_tab',
  StudentsTechCheckDashboard = 'group_dashboard.tech_check_tab',
  StudentsSummaryDashboard = 'group_dashboard.summary_tab',
  StudentsApplyingDashboard = 'group_dashboard.applying_tab',
  StudentsFlexTechCheckDashboard = 'group_dashboard.flex_tech_check_tab',
  CertificateSharingExperiment = 'certificate_sharing_experiment',
  NewFtAgreement = 'ft_agreement_3_years_12_percent',
  // @deprecated
  UseShortAgreementUrl = 'use_short_agreement_url',
  // @deprecated
  RedirectToFlexAgreement = 'redirect_from_parttime_to_flex_agreement_url',
  ReliableExams = 'reliable_exams',
  ReliableExamsUI = 'reliable_exams_ui',
  InterviewFeedbackForm = 'interview_feedback_form',
  FreeStudyingForCombatants = 'free_studying_for_combatants',
  MateGraduatesWorkingInCompany = 'mates_graduates_working_in_company',
  PlatformDemoSection = 'platform_demo_section',
  ShowMultipleCourseAuthors = 'show_multiple_course_authors',
  HomePageExitPopup = 'home_page_exit_popup',
  CoursesParttimePageExitPopup = 'courses_parttime_page_exit_popup',
  ChatLevelSettings = 'notifications_settings_per_chat',
  ChatParticipantQuickActionsModal = 'chat_participant_quick_actions_modal',
  TaskReviewsListView = 'task_reviews_list_view',
  ConsultationAgeInput = 'consultation_age_input',
  LearnAndPracticeCalendarEvent = 'learn_and_practice_event',
  EnglishLessonsQueryPagination = 'english_lessons_query_pagination',
  EntityDetailsOnCheckoutPage = 'entity_details_on_checkout_page',
  AcceptingNewTeamProjectApplications = 'accepting_new_team_project_applications',
  UpdatedLandingMobileHeaderDesign = 'show_updated_landing_header_design',
  StrictPhoneNumbersValidation = 'strict_phone_numbers_validation',
  LandingHeaderLogoutButton = 'landing_header_logout_button',
  AIChatbotHelper = 'ai_chatbot_helper',
  AiChatFeedback = 'ai_chat_feedback',
  ExpandAichatForParttime = 'expand_aichat_for_parttime',
  AichatForAll = 'aichat_for_all',
  AdmissionStatusesOnCoursesPage = 'admission_statuses_on_courses_page',
  ShowDomainsHtmlLinksInFooter = 'show_domains_html_links_in_footer',
  UseCourseScheduleFromDb = 'use_course_schedule_from_db', // TODO: remove (https://app.clickup.com/t/86bx6ab63)
  MonobankPaymentProviderUASubscriptionPayment = 'monobank_payment_provider_ua_subscription_payment',
  DraftChatMessages = 'draft_chat_messages',
  ChatSender = 'chat_sender',
  SyncTaskWithGithub = 'sync_task_with_github',
  SyncTaskWithGithubForMentors = 'sync_task_with_github_for_mentors',
  // @deprecated
  CollectEmailsFromAgreement = 'collect_emails_from_agreement',
  IntroVideo = 'intro_video',
  CourseAdmissionStepper = 'course_admission_stepper',
  ChatNotificationSettingsMobile = 'mobile_notifications_settings',
  ModuleCardRedesign = 'module_card_redesign',
  DashboardFullScreen = 'group_dashboard_fullscreen',
  ShowDurationsForNonBasicModule = 'show_durations_for_non_basic_module',
  ShouldFetchDurations = 'should_fetch_durations',
  StudentAutoTechCheckDashboard = 'group_dashboard.auto_tech_check_tab',
  DashboardBulkMessages = 'dashboard_bulk_messages_sender', // TODO: remove (https://app.clickup.com/t/86bwv1wrn)
  ChatParticipantAvatarWithLeagueFrame = 'chat_participant_avatar_with_league_frame',
  ReviewPluginIterationsCount = 'review_plugin_max_iterations_count',
  ApplicationPageMobileRedesign = 'web_mobile_redesign-application_page',
  ChatDirectMessages = 'chat_dms',
  ApplyingSidebar = 'applying_sidebar',
  GroupDashboardDeadlineUpdating = 'group_dashboard_deadline_updating',
  StreaksFrozenDays = 'streaks_frozen_days',
  PreparationCourseGifts = 'preparation_course_gifts',
  VacanciesDbFilters = 'vacancies-db-filters', // TODO: remove (https://app.clickup.com/t/86bwv1wrn)
  IndividualDeclarationsUploading = 'individual_declarations_uploading',
  UserRegistrationByEmail = 'user_registration_by_email',
  ShowPricesOnPtBrLandings = 'show_prices_on_pt_br_landings',
  PolishEntityMergeAnnouncement = 'polish_entity_merge_announcement',
  DynamicFavicon = 'dynamic_favicon',
  DynamicTitle = 'dynamic_notifications_title',
  ChatSoundNotifications = 'sound_notifications',
  UserOnboarding = 'user_onboarding_ui',
  ChangeTimezoneModal = 'change_time_zone_modal',
  AIAssistantOnMobile = 'ai_assistant_on_mobile',
  /** @deprecated */
  ContractorsPeopleforceManagement = 'contractors_peopleforce_management',
  TrialBanners = 'trial_banners',
  TrialAccess = 'trial_access',
  VideoReviewsPL = 'video_reviews_pl',
  ThreadFollowUnfollow = 'thread_follow_unfollow',
  UserVacancyContact = 'user_vacancy_contact',
  SubscriptionProduct = 'subscription_product',
  SubscriptionProductTechCheckLimit = 'subscription_product_tech_check_limit',
  SubscriptionProductLeadsDMsBlock = 'subscription_product_leads_dms_block',
  SubscriptionStudentNPSSurvey = 'subscription_student_nps_survey',
  StripePaymentProviderUASubscriptionPayments = 'stripe_payment_provider_ua_subscription_payments',
  EveryNWeeksEventSchedule = 'every_n_weeks_event_schedule',
  WebinarMigrationFromWebflow = 'webinar_migration_from_webflow',
  ShowModuleCardRedesign = 'show_module_card_redesign',
  LoadTechChecksByDomain = 'load_tech_checks_by_domain',
  PlatformExamsPlugin = 'platform_exams_plugin',
  BillingAddressForm = 'billing_address_form',
  PeerReviewHWDashboard = 'peer_review_hw_dashboard',
  SubscriptionProductChatPaywall = 'subscription_product_chat_paywall',
  SnipersCharityBanner = 'snipers_charity_banner',
  ChatInPracticePage = 'chats_in_practice_page',
  SurveyAfterConsultation = 'survey_after_consultation',
  /** @deprecated */
  GAAndGTMPartyTown = 'ga_and_gtm_party_town',
  /** @deprecated */
  UseAfterInteractiveForGTM = 'use_after_interactive_for_gtm',
  TopicIconRedesign = 'topic_icon_redesign',
  EmploymentStudentsTasks = 'employment_students_tasks',
  ImageStoringRework = 'images_storing_rework',
  XpForAnswerInThread = 'xp_for_answer_in_thread',
  UserTaskSolution = 'user_task_solution',
  ImprovedTestResults = 'improved_test_results',
  BrazilPriceCardRedesign = 'brazil-price-card-redesign',
  BillingAddressCollectionFT = 'ft_payments_billing_address_collection',
  FTSignUpSurvey = 'ft_sign_up_survey',
  WebflowWebinar = 'webflow_webinar',
  GoogleMeetOAuth = 'google-meet-oauth',
  SimilarChatsOnPracticePage = 'similar_chats_on_practice_page',
  CompletionModuleScreen = 'completion_module_screen',
  GoogleMeetLinkAutogeneration = 'google_meet_link_auto_generation',
  AIReviewTestTasks = 'ai_review_test_tasks',
  ShowExitOnLeavingPopup = 'show_exit_on_leaving_popup',
  ReadChatOnScroll = 'read_chat_on_scroll',
  ReadChatOnScrollPerformanceTest = 'read_chat_on_scroll_performance_test',

  // Promotions | START
  PlatformPromo = 'platform_promo', // platform_promo
  LandingPromoBanners = 'landing_promo_banners', // for all promo banners and cards
  // Promotions | END

  // Limited  basics | START
  LimitedBasicAccessBR = 'limited_basic_access_br',
  LimitedBasicAccessPL = 'limited_basic_access_pl',
  // Limited access basics | END

  // Merged landing | START
  RefactorProfession = 'refactor-profession',
  MergedLanding = 'merged-landing',
  // Merged landing | END

  GamiziedQuiz = 'gamified_quiz',
  AutomatedBonusSubscriptions = 'bonus_subscriptions_automated_issuing',

  ChatStudentTag = 'chat_student_tag',

  FlexTechCheckMigration = 'fx_tc_events_to_calendar_migration',
  StripeBuiltInCheckout = 'stripe_built_in_checkout',
  SubscriptionProductNewPaywall = 'subscription_product_new_paywall',
  StripeBuiltInCheckoutApplePay = 'stripe_built_in_checkout_apple_pay',
  PlatformConsultation = 'platform_unified_consultation',
  EnglishSubscriptionTriggers = 'english_subscription_triggers',
  LearnItemAttempts = 'linear-flow-video-theory-attempts',
  DesktopRedirectModal = 'desktop_redirect_modal',
  ChatRichTextEditor = 'chat_rich_text_editor',
  ValidateVimeoVideoDuration = 'vimeo-video-duration-revalidation',
  TheorySummaryMode = 'theory-summary-in-the-linear-flow',
  LogicTestInterviewIframe = 'logic-test-interview-iframe',
  // RenewSubscription = 'renew_subscription',
  NewSignInWithPasswordlessFlow = 'new_sign_in_with_passwordless_flow',
  MateInstallmentLoanPayments = 'mate_installments_loan_payments',
  SubscriptionProductPricingVariation = 'subscription_product_pricing_variation',
  ChatRichTextEditorAdaptives = 'chat_rich_text_editor_adaptives',
  TestTaskActivity = 'test_task_activity',
  TestTaskActivityResultCache = 'test_task_activity_result_cache',
  TestTaskActivityFakeBannerData = 'test_task_activity_fake_banner_data',
  MorningTaskInLinearFlow = 'morning_task_in_linear_flow',
  EnglishInLinearFlow = 'english_in_linear_flow',
  DailyTaskInLinearFlow = 'daily_task_in_linear_flow',
  ExamInLinearFlow = 'exam_in_linear_flow',
  LiveCodingInLinearFlow = 'live_coding_in_linear_flow',
  TestAssignmentInLinearFlow = 'test_assignment_in_linear_flow',
  ChatPDFUploading = 'chat_pdf_uploading',
  OptionalMentorReviewAfterApprovedByAiReview = 'optional_mentor_review_after_approved_by_ai_reviewer',
  ManuallyRequestMentorReviewAgain = 'manually_request_mentor_review_again',
  MultipleAiReviews = 'multiple_ai_reviews',
  SubscriptionsManagement = 'subscriptions_management',
  RedesignedGithubFlowTaskUI = 'redesigned_github_flow_task_ui',
  CodemirrorInMateTheme = 'codemirror_in_mate_theme',
  MonacoEditorMateTheme = 'monaco_editor_mate_theme',
  MarkdownMateTheme = 'markdown_mate_theme',
  PaymentsPageRedesign = 'payments_page_redesign',
  DeletedTasksBlockInLinearPage = 'deleted_tasks_block',
  ProgressChangesInUpdateTopicModal = 'progress_changes_in_update_topic_modal',
  CountrySelectorRedesign = 'country_selector_redesign',
  ConsultationPromoCodeInput = 'consultation_promo_code_input',
  HelpCrunchIntegration = 'help_crunch_integration',
  GoogleFreeTrialFunnelV2 = 'google_free_trial_funnel_v2',
  PaymentsFromPlLandings = 'payments_from_pl_landings',
  BR2ndLessonCSATSurvey = 'br_2nd_lesson_csat_survey',
  MateLogoAnniversary = 'mate_anniversary_logo',
}
