import getConfig from 'next/config';
import { productNameVar } from '@/controllers/subDomain/withSubDomainApp';
import { getPathName } from '@/lib/helpers/getPathName';
import { Products } from '@/constants/products';

export const getRootClientLink = (
  path: string,
): string => {
  const { publicRuntimeConfig = {} } = getConfig() || {};
  const productName = productNameVar();
  const { API_HOST_PUBLIC, API_HOST_PUBLIC_SUB } = publicRuntimeConfig;

  const pathName = getPathName(path);

  if (productName === Products.Knowely) {
    return `https://${API_HOST_PUBLIC_SUB}${pathName}`;
  }

  return `https://${API_HOST_PUBLIC}${pathName}`;
};
