import { CourseTopicContentType } from '@/controllers/graphql/generated';
import { EnteredUserInfoField } from '@/controllers/consultation/consultation.typedefs';

export const LOCAL_STORAGE_KEYS = {
  selectedStudentsGroupName: 'selected_student_group',
  groupDashboardSelectedStudents: (studentsGroupName: string) => `selected_students_${studentsGroupName}`,
  selectedStudentsGroups: 'selected_student_groups', // changed initial value
  trialTimerStartedAt: 'trial_timer_started_at',
  platformThemePreference: 'platform_theme_preference',
  lastUsedPlatformTheme: 'last_used_platform_theme',
  recruiterDashboardOnboardingCompleted: 'recruiter_dashboard_onboarding_completed',
  admissionProgressCourseUserId: 'admission_page_course_user_id',
  agreementForms: 'agreements_forms',
  moduleCompletedStatus: (moduleSlug: string) => `${moduleSlug}-completed-status`,
  certificateExperimentModalShown: 'certificate-experiment-modal-shown',
  dailyTasksParentCourse: 'daily-tasks-parent-course',
  recommendedEnglishModule: 'recommended_english_module',
  userCommonEnglishLevel: 'user_common_english_level',
  englishDashboardFilters: 'english_dashboard_filters',
  eventsFilters: 'events_filters',
  activeEventsTable: 'active_events_table',
  activeEventsSettingsTable: 'active_events_settings_table',
  guideLineEditorSelectedCourseKey: 'guidelines_editor_selected_course_key',
  guideLineEditorSelectedModuleKey: 'guidelines_editor_selected_module_key',
  isEmploymentReview: 'is_employment',
  reviewComment: 'review_comment',
  htmlComparator: (id: string) => ({
    mode: `${id}_compare_html_mode`,
    opacity: `${id}_compare_html_opacity`,
  }),
  activeModule: (moduleSlug: string) => `activeModule_${moduleSlug}`,
  rejectUsersReapplicationCourseHideAlert: (courseSlug: string) => `rejected_users_reapplication_${courseSlug}_hide_alert`,
  selectedLearnCourseSlug: 'selected_learn_course_slug',
  trialUpgradeRequest: 'trial_upgrade_requested_data',
  nativeAppLinksBanner: 'nativeAppLinksBanner',
  mobileAppMiniBanner: 'mobileAppMiniBanner',
  hasDesktopLinkModalBeenShown: 'has_desktop_link_modal_been_shown',
  videoStartSecond: (videoId: number) => `${videoId}_start_second`,
  wasExamWarningModalShowed: (uuid: string) => `${uuid}-exam-warning-modal-showed`,
  mobileEditorTab: (courseSlug: string, courseTopicSlug: string) => `${courseSlug}_${courseTopicSlug}_mobile_editor_tab`,
  mobilePracticeTab: (
    courseSlug: string,
    courseTopicSlug: string,
    taskSlug: string,
  ) => `${courseSlug}_${courseTopicSlug}_${taskSlug}_practice_tab`,
  blockCollapseTutorial: (blockName: string) => `${blockName}_block_collapse_tutorial`,
  showMentorSolutionDespiteNotAccomplished: (taskSlug: string) => `${taskSlug}: show solution despite not accomplished`,
  courseTopicVideoIndex: (courseTopicSlug: string) => `${courseTopicSlug}_video_index`,
  addAttachmentTaskFormStep: 'add-attachment-task-step',
  addAttachmentTaskFormValues: 'add-attachment-task-values',
  addAttachmentTaskFormDescriptionFiles: 'add-attachment-task-description-files',
  addTaskFormKeysByFlow: (testTaskFlow: string) => ({
    currentStepKey: `add-${testTaskFlow}-task-step`,
    activeFilePathKey: `code-${testTaskFlow}-task-active-path`,
    valuesKey: `add-${testTaskFlow}-task-values`,
    descriptionKey: `add-${testTaskFlow}-task-description-files`,
    filesKey: `add-${testTaskFlow}-task-files`,
  }),
  editTaskFormKeysByFlow: (flow: string, testTaskId: number) => ({
    descriptionKey: `edit-${flow}-task-${testTaskId}-description-files`,
    moduleKey: `edit-${flow}-task-${testTaskId}-module`,
    valuesKey: `edit-${flow}-task-${testTaskId}-values`,
    activeTabKey: `edit-${flow}-task-${testTaskId}-active-tab`,
    filesKey: `edit-${flow}-task-${testTaskId}-files`,
    isNewVersionSubmittedKey: `edit-${flow}-task-${testTaskId}-is-new-version-submitted`, // changed initial value
    currentVersionKey: `edit-${flow}-task-${testTaskId}-version`,
    versionsKey: `edit-${flow}-task-${testTaskId}-versions-data`,
    lastVersionKey: `edit-${flow}-task-${testTaskId}-last-version`,
  }),
  editTaskSelectedDescriptionLang: (taskId: number) => `edit-task-${taskId}-description-selected-lang`,
  addCourseTopicTaskStep: 'add-course-topic-task-step',
  addCourseTopicTaskValues: 'add-course-topic-task-values',
  addModuleFormStep: 'add-module-step',
  addModuleFormValues: 'add-module-values',
  addTopicFormValues: 'add-topic-values',
  addTopicFormTheory: 'topic-theory',
  addTopicFormUsefulLinks: 'topic-useful-links',
  addTopicFormStep: 'add-topic-step',
  editCourseTopicTaskKeys: {
    valuesKey: 'edit-course-topic-task-values',
    activeTabKey: 'edit-course-topic-task-tab',
  },
  editTopic: (
    moduleSlug: string,
    topicSlug: string,
  ) => ({
    theory: `/${moduleSlug}/${topicSlug}/theoryFiles/v1`,
    theoryUpdatedAt: `/${moduleSlug}/${topicSlug}/${CourseTopicContentType.Theory}/theoryFiles/updatedAt/v1`,
  }),
  lmsTaskEditorFullscreenTab: (taskFileName: string) => `${taskFileName}_lms_editor_fullscreen_tab`,
  lmsTaskEditorResultsTab: (taskFileName: string) => `${taskFileName}_lms_editor_results_tab`,
  quizTaskState: 'quiz-task-state',
  quizBuilderStep: 'quiz-builder--step',
  quizBuilderValues: 'quiz-builder--values',
  addTestTaskPreviewValues: 'add-test-task-preview-values',
  selectedCalendarTemplateId: 'selected_template_id',
  techCheckSelectedNodes: 'tech_check_selected_nodes',
  finishedCourseTestAssignment: (courseUserId: number) => `Course-${courseUserId}-finished-test-assignment`,
  lmsTasksBlockVisible: (linkUUID: string) => `lms_tasks_block_visible_${linkUUID}`,
  lmsIsLeftSidebarCollapsed: (key: string) => `${key}_lms_is_left_sidebar_collapsed`,
  lmsIsRightSidebarCollapsed: (key: string) => `${key}_lms_is_right_sidebar_collapsed`,
  lmsResultsBlockVisible: (linkUUID: string) => `lms_results_block_visible_${linkUUID}`,
  taskReviewStep: (linkUUID: string) => `${linkUUID}_task_review_step`,
  codeEditorResultsTab: (linkUUID: string) => `${linkUUID}_results_tab`,
  isLmsFilesManagerVisible: (linkUUID: string) => `lms_files_manager_visible_${linkUUID}`,
  examInsight: {
    reloadedPageWithOpenInterval: 'examInsight::reloadedPageWithOpenInterval',
    reloadedPageWithOpenTabBlurInterval: 'examInsight::reloadedPageWithOpenTabBlurInterval',
  },
  hasSeenTaskSolutionMessage: (taskSlug: string) => `${taskSlug}_has_seen_solution_message`,
  fileManagerOpenedFolders: (taskSlug: string) => `${taskSlug}_file_manager_opened_folders`,
  resizableBlockWidth: (blockKey: string) => `${blockKey}_width`,
  resizableBlockHeight: (blockKey: string) => `${blockKey}_height`,
  createTechCheckParams: 'create_tech_check_params',
  interviewDetails: (interviewDetailsId: number) => ({
    feedbackQuestionsKey: `interviewFeedbackQuestions-${interviewDetailsId}`,
    feedbackDraftSuggestedQuestionsKey: `interviewFeedbackDraftSuggestedQuestions-${interviewDetailsId}`,
    feedbackSuggestedQuestionsKey: `interviewFeedbackSuggestedQuestions-${interviewDetailsId}`,
  }),
  techCheckParticipantLanguage: 'tech-check-language',
  lastVisitedTechCheckTopicId: 'last_visited_tech_check_topic_id',
  lastUsedTechCheckCourseId: 'last_used_tech_check_course_id',
  codeEditorTaskLastActiveFile: (linkUUID: string, taskSlug: string) => `${linkUUID}_${taskSlug}_editor_last_active_file`,
  codeEditorFullScreenTab: (linkUUID: string) => `${linkUUID}_editor_fullscreen_tab`,
  codeEditorLastSelectedTask: (linkUUID: string) => `${linkUUID}_editor_last_selected_task`,
  vacancies: {
    filters: 'vacancies_filters_v2',
    coverLetter: 'vacancies_cover_letter',
    wasActionPerformed: (action: string) => `${action}`,
  },
  studyScheduleBlock: {
    isCollapsed: 'study_schedule_block_collapsed',
  },
  groupDashboards: {
    getSelectedStudentsIds: (groupName: string) => `group_manage_${groupName}_selected_students_ids`,
  },
  chat: {
    chatScrollPositionDeprecated: (chatId: number) => `chat_scroll_position_${chatId}`,
    threadScrollPositionDeprecated: (threadId: number) => `thread_scroll_position_${threadId}`,
    chatScrollPosition: (chatId: number) => ({
      scrollHeight: `chat_scroll_height_${chatId}`,
      isScrolledBottom: `chat_is_scrolled_bottom_${chatId}`,
    }),
    threadScrollPosition: (threadId: number) => ({
      scrollHeight: `thread_scroll_height_${threadId}`,
      isScrolledBottom: `thread_is_scrolled_bottom_${threadId}`,
    }),
    guidelinesScrollPosition: (chatId: number) => `guidelines_scroll_position_${chatId}`,
    chatSectionOpenState: (section: string) => `chat_section_open_state_${section}`,
    isChatSectionExpanded: (sectionId: number) => `chat_section_${sectionId}_expanded`,

    chatNotificationsBanner: 'notifications_banner_dismissed_at',
    isGuidelinesTabOpen: 'is_guidelines_tab_open',

    chatWindowWidth: 'chat_window_width',
  },
  previousPageForSettingsBackButton: 'previous_page_for_settings_back_button',
  firstPlatformVisit: 'first_platform_visit',
  promoCardOpened: (promoName: string) => `${promoName}_card_opened`,
  hasSeenAiCourseVideos: (courseSlug: string) => `has_seen_ai_course_videos_${courseSlug}`,
  showReadMessages: 'show_read_messages',
  systemTimeZone: 'system_time_zone',
  homeworkReviewFilters: 'homework_review_filters',
  vimeoPlaybackRate: 'vimeo_playback_rate',
  wasSnipersBannerClosed: 'was_snipers_banner_closed',
  hasSeenLearnChatButtonAnimation: 'has_seen_learn_chat_button_animation',
  trialBannerClosed: 'trial_banner_closed',
  promoFirstInteractionTime: (promoName: string) => `${promoName}_promo_first_interaction_time`,
  dashboardStudentsEmploymentFilter: 'dashboard_students_employment_filter',
  courseSupportChatState: 'course_support_chat_state',
  referralCode: 'referral_code',
  updateTopicNotification: (courseUserTopicId: number) => `update_topic_notification_${courseUserTopicId}`,
  isTopicCompletionTasksShown: (courseTopicSlug: string, courseSlug: string) => `is_topic_completion_tasks_shown_${courseTopicSlug}_${courseSlug}`,
  moduleCompletionScreenShown: (courseSlug: string) => `module_completion_screen_shown_${courseSlug}`,
  limitedAccessConsultationRequestedAt: 'limited_access_consultation_requested_at',
  platformPromoShown: (userId: number) => `platform_promo_shown_${userId}`,
  platformPromoTimer: (promoName: string) => `platform_promo_timer_${promoName}`,
  richTextEditor: {
    isTopToolbarVisible: 'toolbar_top_is_visible',
  },
  isFreeTrialFunnel: 'is_free_trial_funnel',
  enteredUserInfo: (
    field: EnteredUserInfoField,
  ) => `entered_user_${field}`,
  lastTypeformId: 'last_typeform_id',
  lastTypeformResponseId: 'last_typeform_response_id',
  lastTypeformAnswers: 'last_typeform_answers',
};
